import { forwardRef } from "react"

export const Item = forwardRef(({ children, ...props }, ref) => {
    return (
        <div
            className="relative aspect-video w-full flex-none cursor-pointer overflow-hidden rounded-md"
            {...props}
            ref={ref}>
            {children}
        </div>
    )
})
