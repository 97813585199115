import presentationHooks from "../Presentation/presentation.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useCreatePresentation = function () {
    const { user } = useAuth()

    async function createPresentation(title) {
        const { data, error, status } = await supabase.from("presentations").insert([{ owner: user.id, title: title }])
        return data
    }

    return presentationHooks.usePresentationMutation(createPresentation)
}
