import { useState } from "react"
import { Link } from "react-router-dom"
import BigCard from "../components/BigCard"
import Input from "../components/Input"
import Logo from "../components/Logo"
import FullWIdthSubmitButton from "../components/buttons/FullWIdthSubmitButton"
import { useLogin } from "../hooks/Auth/useLogin"

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const login = useLogin()

    return (
        <BigCard>
            <div className="my-12">
                <Logo className="text-center text-7.5xl" />
                <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                <p className="text-md mt-2 text-center text-gray-600">
                    Or
                    <Link className="font-semibold text-primary-200 hover:text-primary-500" to="/signup">
                        {" "}
                        create your own account here!{" "}
                    </Link>
                </p>
            </div>
            <form
                onSubmit={function (e) {
                    e.preventDefault()
                    login(email, password)
                }}>
                <Input type="email" label="Your email" placeholder="name@example.com" setState={setEmail} required />
                <Input type="password" label="Your password" placeholder="Password" setState={setPassword} required />
                <FullWIdthSubmitButton text="Login" />
            </form>
        </BigCard>
    )
}

export default Login
