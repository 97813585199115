import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"
import slideHooks from "./slide.hooks"

export const useDeleteSlide = function () {
    const { user } = useAuth()

    async function deleteSlide(id) {
        return await supabase.from("slides").delete().eq("id", id)
    }

    return slideHooks.useSlideMutation(deleteSlide)
}
