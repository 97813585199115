import SlideStringToHtml from "../SlideStringToHtml"
import { useRef } from "react"
import { useEffect } from "react"
import layout from "../../services/slideLayout"
import { generateHTML } from "@tiptap/react"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Highlight from "@tiptap/extension-highlight"
import Bold from "@tiptap/extension-bold"
import Code from "@tiptap/extension-code"
import Italic from "@tiptap/extension-italic"
import Strike from "@tiptap/extension-strike"
import History from "@tiptap/extension-history"
import TextAlign from "@tiptap/extension-text-align"
import { Paragraph } from "../editor/extensions/Paragraph"
import { Heading } from "../editor/extensions/Heading"
import { OrderedList } from "../editor/extensions/OrderedList"
import { Image } from "../editor/extensions/Image"
import { BulletList } from "../editor/extensions/Bulletlist"
import { Blockquote } from "../editor/extensions/Blockquote"
import { VirginParagraph } from "./extensions/VirginParagraph"
import { ListItem } from "./extensions/ListItem"

export default function SlideThumbnail({ slide }) {
    const slidesRef = useRef()
    const viewportRef = useRef()
    const presentRef = useRef()

    useEffect(() => {
        layout(slidesRef.current, viewportRef.current, presentRef.current)
    }, [slide])

    return (
        <div ref={viewportRef} className="reveal reveal-viewport slide embedded focused ready absolute inset-0">
            <div ref={slidesRef} className="slides">
                <section ref={presentRef} className="present">
                    <SlideStringToHtml
                        htmlString={generateHTML(JSON.parse(slide.content), [
                            Document,
                            Text,
                            TextAlign.configure({
                                types: ["heading", "paragraph"],
                                defaultAlignment: "center",
                            }),
                            Paragraph,
                            VirginParagraph,
                            Heading,
                            Highlight,
                            Image,
                            OrderedList,
                            BulletList,
                            ListItem,
                            Blockquote,
                            Bold,
                            Code,
                            Strike,
                            Italic,
                            History,
                        ])}
                    />
                </section>
            </div>
        </div>
    )
}
