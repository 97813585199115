import { Switch } from "@headlessui/react"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export default function Toggle({ enabled, setEnabled, screenReaderDescription, disabled = false }) {
    return (
        <Switch
            disabled={disabled}
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
                enabled ? "bg-gray-400" : "bg-gray-400",
                disabled ? "pointer-events-none" : "cursor-pointer",
                "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-tertiary focus:ring-offset-2"
            )}>
            <span className="sr-only">{screenReaderDescription}</span>
            <span
                aria-hidden="true"
                className={classNames(
                    enabled ? "translate-x-0" : "translate-x-5",
                    disabled ? "bg-gray-600" : "bg-white",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out"
                )}
            />
        </Switch>
    )
}
