import { supabase } from "./supabaseClient"

function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve({ height: img.height, width: img.width })
        img.onerror = reject
        img.src = URL.createObjectURL(file)
    })
}

export default async function uploadSlideImage(file) {
    try {
        const fileExt = file.name.split(".").pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage.from("slideimages").upload(filePath, file)

        if (uploadError) {
            throw uploadError
        }

        const { publicURL, error } = supabase.storage.from("slideimages").getPublicUrl(filePath)

        const { width, height } = await getImageDimensions(file)

        if (error) {
            throw uploadError
        }

        return { publicURL: publicURL, width: width, height: height }
    } catch (error) {
        alert(error.message)
        return false
    }
}
