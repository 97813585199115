import { useQuery } from "react-query";
import { useAuth } from "../../contexts/Auth";
import { supabase } from "../../services/supabaseClient";

export const useSettingsQuery = function (select) {
    const { user } = useAuth();

    async function fetchSettings() {
        const { data, error, status } = await supabase.from("settings").select().eq("id", user.id).single();
        return data;
    }

    return useQuery(["settings"], fetchSettings, { select });
};
