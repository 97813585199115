import loadable from "@loadable/component";

function IconButton({ onClick, iconName, className, ...props }) {
    const Icon = loadable(() => import("react-icons/md/index.js"), {
        resolveComponent: (components) => components[iconName],
    });

    return (
        <button
            onClick={function () {
                onClick();
            }}
            {...props}
            className={`font-xs inline-flex items-center rounded-lg p-2 text-center text-xs ${
                className ? className : "bg-slate-200 text-slate-800 hover:bg-slate-300"
            }`}
        >
            <Icon className="h-5 w-5" />
        </button>
    );
}

export default IconButton;
