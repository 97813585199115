import { useEffect } from "react"
import { useState } from "react"
import SortableList from "./SortableList"
import slideHooks from "../../../hooks/Content/slide.hooks"
import SlideThumbnail from "../SlideThumbnail"
import { MdOutlineClose } from "react-icons/md"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

export default function SlideSidebar({ isSuccess, data }) {
    const params = useParams()
    const navigate = useNavigate()
    const [items, setItems] = useState(false)
    const updateSlides = slideHooks.useUpdateSlides()
    const deleteSlide = slideHooks.useDeleteAndUpdateSlide()

    useEffect(() => {
        if (isSuccess) {
            setItems(data.sort((a, b) => a.ranking - b.ranking))
        }
    }, [isSuccess, data])

    function handleChange(dataOrder) {
        setItems(dataOrder)

        const newOrder = dataOrder.map((slide, index) => {
            slide.ranking = index
            return slide
        })

        updateSlides.mutate({ rows: newOrder, id: newOrder[0].presentation_id })
    }

    function handleDelete(id) {
        const newItems = items.filter((item) => item.id !== id)
        const newItemsOrder = newItems.map((slide, index) => {
            slide.ranking = index
            return slide
        })

        setItems(newItemsOrder)

        // if params.slideId is the same as the id of the slide being deleted, then redirect to the first slide in the presentation
        if (params.slideId === id) {
            navigate(`/presentations/${params.presentationId}/${newItemsOrder[0].id}`)
        }

        deleteSlide.mutate({ rows: newItemsOrder, slideId: id, presentation_id: newItemsOrder[0].presentation_id })
    }

    return (
        <>
            {items ? (
                <SortableList
                    data={items}
                    onChange={handleChange}
                    renderItem={(slide) => (
                        <div className="group select-none">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(slide.id)
                                }}
                                className="absolute top-0 right-0 z-50 mt-1 mr-1 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-white opacity-0 transition duration-500 ease-in-out hover:scale-110 group-hover:opacity-100">
                                <MdOutlineClose />
                            </div>
                            <SlideThumbnail slide={slide} />
                        </div>
                    )}
                />
            ) : null}
        </>
    )
}
