import EditorButton from "./EditorButton"
import "material-symbols/rounded.css"
import { useCallback } from "react"
import uploadSlideImage from "../../services/uploadSlideImage"

export default function SlideEditorMenuBar({ editor }) {
    const addImage = useCallback(
        async (filePath) => {
            const { publicURL, width, height } = await uploadSlideImage(filePath)

            if (publicURL) {
                editor.chain().focus("end").setImage({ src: publicURL, width: width, height: height }).run()
            }
        },
        [editor]
    )

    if (!editor) {
        return null
    }

    return (
        <div className="flex gap-3">
            <div className="h-1 w-64 flex-none"></div>
            <div className="flex w-full justify-center">
                <div className="mb-4 flex w-full justify-between">
                    <div className="flex gap-3 ">
                        <EditorButton
                            // Bold
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            disabled={!editor.can().chain().focus().toggleBold().run()}
                            className={editor.isActive("bold") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_bold"
                        />

                        <EditorButton
                            // Italic
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            disabled={!editor.can().chain().focus().toggleItalic().run()}
                            className={editor.isActive("italic") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_italic"
                        />

                        <EditorButton
                            // Strike
                            onClick={() => editor.chain().focus().toggleStrike().run()}
                            disabled={!editor.can().chain().focus().toggleStrike().run()}
                            className={editor.isActive("strike") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_strikethrough"
                        />

                        <EditorButton
                            // H1
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                            className={editor.isActive("heading", { level: 1 }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_h1"
                        />

                        <EditorButton
                            // H2
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                            className={editor.isActive("heading", { level: 2 }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_h2"
                        />

                        <EditorButton
                            // H3
                            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                            className={editor.isActive("heading", { level: 3 }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_h3"
                        />

                        <EditorButton
                            // Highlight
                            onClick={() => editor.chain().focus().toggleHighlight().run()}
                            className={editor.isActive("highlight") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="highlight"
                        />

                        <EditorButton
                            // Code
                            onClick={() => editor.chain().focus().toggleCode().run()}
                            disabled={!editor.can().chain().focus().toggleCode().run()}
                            className={editor.isActive("code") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="code"
                        />

                        <EditorButton
                            // Align-Left
                            onClick={() => editor.chain().focus().setTextAlign("left").run()}
                            className={editor.isActive({ textAlign: "left" }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_align_left"
                        />

                        <EditorButton
                            // Align-Center
                            onClick={() => editor.chain().focus().setTextAlign("center").run()}
                            className={editor.isActive({ textAlign: "center" }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_align_center"
                        />

                        <EditorButton
                            // Align-Right
                            onClick={() => editor.chain().focus().setTextAlign("right").run()}
                            className={editor.isActive({ textAlign: "right" }) ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_align_right"
                        />

                        <EditorButton
                            // Bullet-List
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                            className={editor.isActive("bulletList") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_list_bulleted"
                        />

                        <EditorButton
                            // Numbered-List
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                            className={editor.isActive("orderedList") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_list_numbered"
                        />

                        <EditorButton
                            // Quote
                            onClick={() => editor.chain().focus().toggleBlockquote().run()}
                            className={editor.isActive("blockQuote") ? "bg-slate-400" : "bg-slate-200"}
                            buttonName="format_quote"
                        />

                        <EditorButton
                            // Clear
                            onClick={() => editor.chain().focus().unsetAllMarks().run()}
                            className="bg-slate-200"
                            buttonName="format_clear"
                        />
                    </div>

                    <div className="flex gap-3">
                        <EditorButton
                            // Paragraph
                            onClick={() => editor.chain().focus("end").createParagraphNear().insertContent("<p>type something</p>").run()}
                            className={"bg-slate-200"}
                            buttonName="format_paragraph"
                        />

                        <div
                            className={`relative inline-flex h-9 w-9 items-center justify-center rounded-lg bg-slate-200 text-slate-800 hover:bg-slate-300`}>
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onClick={function (e) {
                                    e.target.value = ""
                                }}
                                onChange={function (e) {
                                    console.log(e)
                                    addImage(e.target.files[0])
                                }}
                                className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                            />
                            <span className="material-symbols-rounded text-2xl">image</span>
                        </div>
                    </div>

                    <div className="flex gap-3">
                        <EditorButton
                            // Undo
                            onClick={() => editor.chain().focus().undo().run()}
                            disabled={!editor.can().chain().focus().undo().run()}
                            className="bg-slate-200"
                            buttonName="undo"
                        />

                        <EditorButton
                            // Redo
                            onClick={() => editor.chain().focus().redo().run()}
                            disabled={!editor.can().chain().focus().redo().run()}
                            className="bg-slate-200"
                            buttonName="redo"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
