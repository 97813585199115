import React from "react"
import { NavbarLink } from "./NavLink.Sidebar"
import SidebarUser from "./User.Sidebar"
import { useAuth } from "../../contexts/Auth"
import { useNavigate } from "react-router-dom"

function SidebarFooter() {
    const { signOut } = useAuth()
    const navigate = useNavigate()

    async function handleSignOut() {
        await signOut()
        navigate("/")
    }

    return (
        <div className="bottom-0 mt-4 space-y-2 border-t border-gray-200 pt-4">
            <SidebarUser />
            <NavbarLink onClick={handleSignOut} text="Logout" iconName="MdLogout" />
        </div>
    )
}

export default SidebarFooter
