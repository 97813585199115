function FullWIdthSubmitButton({ text, ...props }) {
    return (
        <button
            type="submit"
            {...props}
            className="w-full rounded-lg bg-primary-500 px-5 py-2.5 text-center text-sm font-bold text-white hover:bg-primary-600 focus:ring-4 focus:ring-primary-300">
            {text}
        </button>
    )
}

export default FullWIdthSubmitButton
