import { useUserQuery } from "../../hooks/User/useUserQuery"

function SidebarUser() {
    const { isSuccess, data } = useUserQuery()

    return (
        <div className="my-4 flex items-center">
            {isSuccess ? (
                <>
                    {data.avatar_url ? (
                        <img className="h-12 w-12 flex-none rounded-full object-cover" src={data.avatar_url} alt="" />
                    ) : (
                        <div className="h-12 w-12 flex-none rounded-full bg-gray-300"> </div>
                    )}
                </>
            ) : (
                <div className="h-12 w-12 flex-none animate-pulse rounded-full bg-gray-300"> </div>
            )}

            <div className="ml-3 flex max-w-full flex-col justify-between overflow-hidden">
                {isSuccess ? (
                    <div>
                        <div className="truncate text-lg font-light">{`${data.firstname} ${data.lastname}`}</div>
                        <div className="truncate text-sm font-light">@{data.username}</div>
                    </div>
                ) : (
                    <div className="flex animate-pulse gap-3 py-1.5">
                        <div className="h-4 grow rounded-sm bg-gray-300"> </div>
                        <div className="h-4 grow rounded-sm bg-gray-300"> </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SidebarUser
