import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import Login from "../../views/Login"
import Content from "../../views/Content"
import Presentations from "../../views/Presentations"
import Layout from "../../views/Layout"
import Signup from "../../views/Signup"
import Talk from "../../views/Talk"
import Settings from "../../views/Settings"
import Remote from "../../views/Remote"

function Routing() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }>
                    <Route
                        path="settings"
                        element={
                            <PrivateRoute>
                                <Settings />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <Presentations />
                            </PrivateRoute>
                        }
                    />

                    <Route path="*" element={<Navigate to="/" />} />
                </Route>

                <Route
                    path="presentations/:presentationId/:slideId"
                    element={
                        <PrivateRoute>
                            <Content />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="talk/:presentationId"
                    element={
                        <PrivateRoute>
                            <Talk />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="remote/:presentationId"
                    element={
                        <PrivateRoute>
                            <Remote />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default Routing
