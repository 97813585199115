import { useEffect } from "react"
import { useState } from "react"

function IsTranscriptionActive({ sio }) {
    const [isTranscriptionActive, setIsTranscriptionActive] = useState(false)

    useEffect(() => {
        sio.on("startStream", () => {
            setIsTranscriptionActive(true)
        })

        sio.on("endStream", () => {
            setIsTranscriptionActive(false)
        })

        return () => {
            sio.off("startStream")
            sio.off("endStream")
        }
    }, [sio])

    return (
        <div className="absolute top-3 right-3 z-50 flex">
            <div className={`absolute top-0 right-0 h-3 w-3 rounded-full ${isTranscriptionActive ? "bg-green-400" : "bg-gray-500"}`}></div>
            <div
                className={`absolute top-0 right-0 h-3 w-3 animate-ping rounded-full bg-green-400 ${
                    isTranscriptionActive ? "opacity-100" : "opacity-0"
                }`}></div>
        </div>
    )
}

export default IsTranscriptionActive
