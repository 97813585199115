import Routing from "./components/routing/Routing"
import { AuthProvider } from "./contexts/Auth"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

const App = function () {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Routing />
            </AuthProvider>
        </QueryClientProvider>
    )
}

export default App
