import { NavbarLink } from "./NavLink.Sidebar"
import Logo from "../Logo"
import { useNavigate } from "react-router-dom"
import { useUserQuery } from "../../hooks/User/useUserQuery"

function SidebarNavigation() {
    const navigate = useNavigate()
    const { isSuccess, data } = useUserQuery()

    return (
        <div>
            <Logo className="mt-3 mb-6 text-5xl" />
            <div className="space-y-2" id="Navigation">
                <NavbarLink
                    onClick={function () {
                        navigate("/")
                    }}
                    text="Presentations"
                    iconName="MdSlideshow"
                />
                <NavbarLink
                    onClick={function () {
                        navigate("/settings")
                    }}
                    text="Settings"
                    iconName="MdSettings"
                />
            </div>
            {isSuccess && data.role === "ADMIN" && (
                <div className="mt-4 space-y-2 border-t border-gray-200 pt-4">
                    <NavbarLink
                        onClick={function () {
                            navigate("/admin/users")
                        }}
                        text="Admin"
                        iconName="MdAdminPanelSettings"
                    />
                </div>
            )}
        </div>
    )
}

export default SidebarNavigation
