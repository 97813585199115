import { MdFullscreen, MdFullscreenExit } from "react-icons/md"
import { enterFullscreen } from "reveal.js/js/utils/util"

function FullScreenHandler() {
    function fs() {
        enterFullscreen(document.querySelector(".presentation-wrapper"))
    }

    return (
        <button onClick={fs} className="fsbutton">
            <MdFullscreenExit className="h-7 w-7 text-white" />
        </button>
    )
}

export default FullScreenHandler
