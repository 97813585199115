import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"
import slideHooks from "./slide.hooks"

export const useDeleteAndUpdateSlide = function () {
    const { user } = useAuth()

    async function deleteAndUpdateSlide({ rows, slideId, presentation_id }) {
        await supabase.from("slides").delete().eq("id", slideId)
        const { data } = await supabase.from("slides").upsert(rows).match({ presentation_id: presentation_id, owner: user.id })

        return data
    }

    return slideHooks.useSlideMutation(deleteAndUpdateSlide)
}
