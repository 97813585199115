import { useDroppable } from "@dnd-kit/core"

export default function Droppable({ gridSize, ...props }) {
    const { setNodeRef, isOver } = useDroppable({
        id: "droppable",
    })

    return (
        <div
            className={`Grid transition-opacity duration-500 ease-in-out ${isOver ? "opacity-40" : "opacity-0"}`}
            style={{
                "--grid-size": `${gridSize}px`,
            }}
            {...props}
            ref={setNodeRef}>
            {props.children}
        </div>
    )
}
