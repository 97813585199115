import { supabase } from "./supabaseClient";

export default async function uploadAvatar(file) {
    try {
        const fileExt = file.name.split(".").pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${fileName}`;

        let { error: uploadError } = await supabase.storage.from("avatars").upload(filePath, file);

        if (uploadError) {
            throw uploadError;
        }

        const { publicURL, error } = supabase.storage.from("avatars").getPublicUrl(filePath);

        if (error) {
            throw uploadError;
        }

        return publicURL;
    } catch (error) {
        alert(error.message);
    }
}
