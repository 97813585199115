import { useUpdateUser } from "./useUpdateUser"
import { useSettingsQuery } from "./useSettingsQuery"
import { useUserMutation } from "./useUserMutation"
import { useUserQuery } from "./useUserQuery"
import { useSettingsMutation } from "./useSettingsMutation"
import { useUpdateSettings } from "./useUpdateSettings"
import { useDeleteUser } from "./useDeleteUser"

const userHooks = {
    useSettingsQuery,
    useUserMutation,
    useUserQuery,
    useSettingsMutation,
    useUpdateSettings,
    useUpdateUser,
    useDeleteUser,
}

export default userHooks
