import { useCreateSlide } from "./useCreateSlide"
import { useDeleteSlide } from "./useDeleteSlide"
import { useSlideMutation } from "./useSlideMutation"
import { useSlidesQuery } from "./useSlidesQuery"
import { useUpdateSlide } from "./useUpdateSlide"
import { useUpdateSlides } from "./useUpdateSlides"
import { useDeleteAndUpdateSlide } from "./useDeleteAndUpdateSlide"

const slideHooks = {
    useCreateSlide,
    useDeleteSlide,
    useSlideMutation,
    useSlidesQuery,
    useUpdateSlide,
    useUpdateSlides,
    useDeleteAndUpdateSlide,
}

export default slideHooks
