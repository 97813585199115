import { useState, useRef, useEffect } from "react"
import RecordRTC, { StereoAudioRecorder } from "recordrtc"
import { MdPlayArrow, MdPause } from "react-icons/md"
import { useParams } from "react-router-dom"
import { useSocket } from "../hooks/Socket/useSocket"

export default function Remote() {
    const [playSwitch, setPlaySwitch] = useState(true)
    const recorderRef = useRef(null)
    const params = useParams()
    const sio = useSocket(params.presentationId)
    const [error, setError] = useState(null)

    const startStream = async function () {
        if (sio) {
            setPlaySwitch(false)
            const mediastream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

            recorderRef.current = new RecordRTC(mediastream, {
                type: "audio",
                mimeType: "audio/wav",
                desiredSampRate: 16000,
                timeSlice: 1000,
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                ondataavailable: function (blob) {
                    console.log("send something to the server")
                    sio.emit("audio_stream", blob)
                },
            })

            sio.emit("start_audio_stream")
            recorderRef.current.startRecording()
        } else {
            alert("No connection to server")
        }
    }

    const pauseStream = function () {
        setPlaySwitch(true)
        sio.emit("pause_audio_stream")
        recorderRef.current.stopRecording()
    }

    // emit register_remote_audio_stream once the component is mounted and the socket is connected

    // register the event listeners for RemoteAudioStreamNotInitialized, RemoteAudioStreamNotAuthorized, RemoteAudioStreamPresentationAlreadyRunning and RemoteAudioStreamAlreadyRegistered
    // and set the error state accordingly
    useEffect(() => {
        if (sio) {
            sio.emit("register_remote_audio_stream")

            sio.on("RemoteAudioStreamRegistered", () => {
                // if error is not null, set it to null
                if (error) {
                    setError(null)
                }
            })

            sio.on("RemoteAudioStreamNotInitialized", () => {
                setError("The presentation you are trying to connect to has not been initialized yet or doesn't exist.")
            })

            sio.on("RemoteAudioStreamNotAuthorized", () => {
                setError("You are not authorized to connect to this presentation.")
            })

            sio.on("RemoteAudioStreamPresentationAlreadyRunning", () => {
                setError("You can't connect to this presentation because it is already running.")
            })

            sio.on("RemoteAudioStreamAlreadyRegistered", () => {
                setError("You can't connect to this presentation because a remote audio stream has already been registered.")
            })

            return () => {
                sio.off("RemoteAudioStreamRegistered")
                sio.off("RemoteAudioStreamNotInitialized")
                sio.off("RemoteAudioStreamNotAuthorized")
                sio.off("RemoteAudioStreamPresentationAlreadyRunning")
                sio.off("RemoteAudioStreamAlreadyRegistered")
            }
        }
    }, [sio])

    return (
        <div className="flex h-screen items-center justify-center">
            {/**
             * If the error state is set, create an overlay with the error message.
             * The overlay should blur the background slightly and have a gray translucent background.
             * The error message should be centered in the middle of the screen.
             * There should be a button to retry to register the remote audio stream.
             */}
            {error && (
                <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-sm backdrop-filter">
                    <div className="rounded-md bg-white p-4 shadow-md">
                        <p className="text-center text-xl font-bold text-red-500">{error}</p>
                    </div>
                    <button
                        className="absolute top-3 right-3 rounded-md bg-white p-2 shadow-md"
                        onClick={() => {
                            sio.emit("register_remote_audio_stream")
                        }}>
                        Retry
                    </button>
                </div>
            )}

            <button
                onClick={function () {
                    playSwitch ? startStream() : pauseStream()
                }}
                className="rounded-full bg-gray-100">
                {playSwitch ? <MdPlayArrow className="h-48 w-48 text-gray-500" /> : <MdPause className="h-48 w-48 text-gray-500" />}
            </button>
        </div>
    )
}
