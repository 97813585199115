import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"
import noteHooks from "./note.hooks"

export const useUpdateNote = function () {
    const { user } = useAuth()

    async function updateNote({ id, notes }) {
        const { data } = await supabase.from("slides").update({ notes: notes }).match({ id: id, owner: user.id })

        return data
    }

    return noteHooks.useNoteMutation(updateNote)
}
