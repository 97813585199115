import { useState, useMemo } from "react"
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import SortableOverlay from "./SortableOverlay"
import { SortableItem } from "./SortableItem"
import { Item } from "./Item"
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"
import { useNavigate, useParams } from "react-router-dom"

export default function SortableList({ data, onChange, renderItem }) {
    const [active, setActive] = useState(null)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: { y: 15 },
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )
    const params = useParams()
    const navigate = useNavigate()

    const activeItem = useMemo(() => data.find((slide) => slide.id === active?.id), [active, data])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
                setActive(active)
            }}
            onDragEnd={({ active, over }) => {
                if (over && active.id !== over?.id) {
                    const activeIndex = data.findIndex(({ id }) => id === active.id)
                    const overIndex = data.findIndex(({ id }) => id === over.id)

                    onChange(arrayMove(data, activeIndex, overIndex))
                }
                setActive(null)
            }}
            onDragCancel={() => {
                setActive(null)
            }}
            modifiers={[restrictToFirstScrollableAncestor]}>
            <SortableContext items={data} strategy={verticalListSortingStrategy}>
                {data.map((slide) => {
                    return (
                        <SortableItem
                            onClick={() => {
                                navigate(`/presentations/${params.presentationId}/${slide.id}`)
                            }}
                            key={slide.id}
                            id={slide.id}>
                            {renderItem(slide)}
                        </SortableItem>
                    )
                })}
            </SortableContext>
            <SortableOverlay>{activeItem ? <Item>{renderItem(activeItem)}</Item> : null}</SortableOverlay>
        </DndContext>
    )
}
