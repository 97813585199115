import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"
import slideHooks from "./slide.hooks"

export const useUpdateSlides = function () {
    const { user } = useAuth()

    async function updateSlides({ id, rows }) {
        const { data } = await supabase.from("slides").upsert(rows).match({ id: id, owner: user.id })

        return data
    }

    return slideHooks.useSlideMutation(updateSlides)
}
