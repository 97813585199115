import presentationHooks from "../Presentation/presentation.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useUpdatePresentation = function () {
    const { user } = useAuth()

    async function updatePresentation({ id, title }) {
        const { data, error, status } = await supabase.from("presentations").update({ title: title }).match({ id: id, owner: user.id })

        return data
    }

    return presentationHooks.usePresentationMutation(updatePresentation)
}
