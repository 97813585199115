import { useMutation, useQueryClient } from "react-query"

export const usePresentationMutation = function (mutatePresentation) {
    const queryClient = useQueryClient()
    return useMutation(mutatePresentation, {
        onSuccess: () => {
            queryClient.invalidateQueries(["presentations"])
        },
    })
}
