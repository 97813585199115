function Input({ type, label, placeholder, setState, defaultValue, ...props }) {
    return (
        <div className="mb-6">
            <label className="mb-2 block text-sm font-medium text-gray-900">{label}</label>
            <input
                defaultValue={defaultValue}
                onChange={function (e) {
                    setState(e.target.value)
                }}
                type={type}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                placeholder={placeholder}
                {...props}
            />
        </div>
    )
}

export default Input
