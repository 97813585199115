import { MdOutlineAddCircle } from "react-icons/md"
import CreatePresentationModal from "../modals/CreatePresentation.Modal"
import { useState } from "react"

function ModalButton({ text }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <CreatePresentationModal isOpen={isOpen} setIsOpen={setIsOpen} />

            <button
                onClick={() => setIsOpen(true)}
                className="font-sm group inline-flex items-center gap-2 rounded-lg bg-gray-100 px-3 text-center text-sm text-gray-900 hover:bg-primary-50 hover:text-primary-700">
                <MdOutlineAddCircle className="h-5 w-5 text-gray-500 group-hover:text-primary-500" />
                {text}
            </button>
        </>
    )
}

export default ModalButton
