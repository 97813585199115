import { useQuery } from "react-query"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useSlidesQuery = function (presentationId) {
    const { user } = useAuth()

    async function fetchSlides(presentationId) {
        const { data, error, status } = await supabase.from("slides").select().match({ presentation_id: presentationId, owner: user.id })
        return data
    }

    return useQuery(["slides", presentationId], () => fetchSlides(presentationId))
}
