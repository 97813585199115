import Droppable from "./draggable/Droppable"
import { restrictToDroppableContainer } from "../../services/restrictToDroppableContainer"
import { createSnapModifier } from "@dnd-kit/modifiers"
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { useMemo, useRef, useCallback, useEffect } from "react"
import { EditorContent } from "@tiptap/react"
import slideHooks from "../../hooks/Content/slide.hooks"
import layout from "../../services/slideLayout"
import { useScale } from "../../contexts/SlideScale"
import { useDebouncedCallback } from "use-debounce"

export default function SlideEditor({ params, data, isSuccess, editor }) {
    const { setSlideScale } = useScale()
    const updateSlide = slideHooks.useUpdateSlide()
    const revealRootRef = useRef(null)
    const slidesRef = useRef()
    const presentRef = useRef()
    const gridSize = 25
    const snapToGrid = useMemo(() => createSnapModifier(gridSize), [gridSize])

    const debouncedSlideChangeHandler = useDebouncedCallback(
        (editor) => {
            updateSlide.mutate({ id: params.slideId, content: editor.getJSON() })
        },
        500,
        { maxWait: 5000 }
    )

    const sensors = useSensors(
        // Instead of constrain maybe do draghandle
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    )

    useEffect(() => {
        if (isSuccess && editor) {
            editor.commands.clearContent()
            editor.commands.setContent(
                JSON.parse(
                    data.find((obj) => {
                        return obj.id === params.slideId
                    }).content
                )
            )
        }
    }, [isSuccess, editor, params])

    useEffect(() => {
        if (isSuccess && editor) {
            editor.off("update")
            editor.on("update", ({ editor }) => debouncedSlideChangeHandler(editor))
        }
    }, [editor, isSuccess, debouncedSlideChangeHandler])

    useEffect(() => {
        const scale = layout(slidesRef.current, revealRootRef.current, presentRef.current)

        setSlideScale(scale)
    }, [data])

    return (
        <div className="relative aspect-video flex-none">
            <DndContext modifiers={[restrictToDroppableContainer, snapToGrid]} sensors={sensors}>
                <div ref={revealRootRef} className="reveal reveal-viewport slide embedded focused ready deck overflow-hidden rounded-lg">
                    <div ref={slidesRef} className="slides">
                        <section ref={presentRef} className="present">
                            <EditorContent editor={editor} />
                        </section>
                    </div>
                    <Droppable gridSize={gridSize} />
                </div>
            </DndContext>
        </div>
    )
}
