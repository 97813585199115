import { NodeViewContent, NodeViewWrapper } from "@tiptap/react"
import { useDraggable, useDndMonitor } from "@dnd-kit/core"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Resizable } from "re-resizable"
import { useScale } from "../../../contexts/SlideScale"

export default function Draggable({ as, props }) {
    const [id, setId] = useState(uuidv4())
    const [{ x, y }, setCoordinates] = useState({
        x: props.node.attrs.left,
        y: props.node.attrs.top,
    })

    const [{ width, height }, setDimensions] = useState({
        width: props.node.attrs.width,
        height: props.node.attrs.height,
    })

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: id,
    })

    const { slideScale } = useScale()

    useDndMonitor({
        onDragEnd({ delta, active }) {
            if (id === active.id) {
                console.log(active.id)
                props.updateAttributes({
                    left: x + delta.x / slideScale,
                    top: y + delta.y / slideScale,
                })

                setCoordinates(({ x, y }) => {
                    return {
                        x: x + delta.x / slideScale,
                        y: y + delta.y / slideScale,
                    }
                })
            }
        },
    })

    const style = transform
        ? {
              transform: `translate(${transform.x / slideScale}px, ${transform.y / slideScale}px)`,
          }
        : undefined

    return (
        <NodeViewWrapper>
            <div
                className="pointer-events-auto z-50 w-fit border-red-500"
                ref={setNodeRef}
                style={{ ...style, transformStyle: "preserve-3d", position: "absolute", left: `${x}px`, top: `${y}px` }}
                {...listeners}
                {...attributes}>
                <Resizable
                    lockAspectRatio={props.node.attrs.src ? true : false}
                    scale={slideScale}
                    size={{
                        width: width,
                        height: height,
                    }}
                    onResizeStop={(event, direction, ref, delta) => {
                        props.updateAttributes({
                            width: width + delta.width,
                            height: height + delta.height,
                        })

                        setDimensions(({ width, height }) => {
                            return {
                                width: width + delta.width,
                                height: height + delta.height,
                            }
                        })
                    }}>
                    <NodeViewContent
                        style={{ textAlign: `${props.node.attrs.textAlign}` }}
                        as={as}
                        src={props.node.attrs.src ? props.node.attrs.src : null}
                        className="h-full w-full"
                    />
                </Resizable>
            </div>
        </NodeViewWrapper>
    )
}
