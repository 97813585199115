import { useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/Auth"
import uploadAvatar from "../../services/uploadAvatar"

export const useSignUp = function () {
    const navigate = useNavigate()
    const { signUp } = useAuth()

    return async function signup(email, password, username, firstname, lastname, profilephoto) {
        const { user, session, error } = await signUp(
            {
                email: email,
                password: password,
            },
            {
                data: {
                    user_name: username,
                    first_name: firstname,
                    last_name: lastname,
                    avatar_url: profilephoto ? await uploadAvatar(profilephoto) : undefined,
                },
            }
        )

        if (error) {
            alert("error signing up")
        } else {
            navigate("/")
        }
    }
}
