import { useQuery } from "react-query"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useUserQuery = function (select) {
    const { user } = useAuth()

    async function fetchUser() {
        const { data, error, status } = await supabase.from("profiles").select().eq("id", user.id).single()
        return data
    }

    return useQuery(["user"], fetchUser, { select })
}
