import { useMutation, useQueryClient } from "react-query"

export const useNoteMutation = function (mutateNote) {
    const queryClient = useQueryClient()
    return useMutation(mutateNote, {
        onSuccess: () => {
            // this is very lazy, should just partially update the cache instead of doing a whole new refetch...
            queryClient.invalidateQueries(["slides"])
        },
    })
}
