import { mergeAttributes, Node, textblockTypeInputRule } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const Heading = Node.create({
    name: "heading",

    addOptions() {
        return {
            levels: [1, 2, 3, 4, 5, 6],
            HTMLAttributes: {},
        }
    },

    content: "inline*",

    group: "block",

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            level: {
                default: 1,
                rendered: false,
            },

            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
                default: 100,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
                default: 100,
            },
        }
    },

    parseHTML() {
        return this.options.levels.map((level) => ({
            tag: `h${level}`,
            attrs: { level },
        }))
    },

    renderHTML({ node, HTMLAttributes }) {
        const hasLevel = this.options.levels.includes(node.attrs.level)
        const level = hasLevel ? node.attrs.level : this.options.levels[0]

        return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px; width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
            }),
            0,
        ]
    },

    addCommands() {
        return {
            setHeading:
                (attributes) =>
                ({ commands }) => {
                    if (!this.options.levels.includes(attributes.level)) {
                        return false
                    }

                    return commands.setNode(this.name, attributes)
                },
            toggleHeading:
                (attributes) =>
                ({ commands }) => {
                    if (!this.options.levels.includes(attributes.level)) {
                        return false
                    }

                    return commands.toggleNode(this.name, "paragraph", attributes)
                },
        }
    },

    addKeyboardShortcuts() {
        return this.options.levels.reduce(
            (items, level) => ({
                ...items,
                ...{
                    [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level }),
                },
            }),
            {}
        )
    },

    addInputRules() {
        return this.options.levels.map((level) => {
            return textblockTypeInputRule({
                find: new RegExp(`^(#{1,${level}})\\s$`),
                type: this.type,
                getAttributes: {
                    level,
                },
            })
        })
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => Draggable({ as: `h${props.node.attrs.level}`, props: props }))
    },
})
