import { MdSubtitles } from "react-icons/md";

function TranscriptionHandler({ transcriptionSwitch, setTranscriptionSwitch }) {
    return (
        <button
            onClick={function () {
                setTranscriptionSwitch(!transcriptionSwitch);
            }}
        >
            <MdSubtitles className={`h-7 w-7 ${transcriptionSwitch ? "text-primary-500" : "text-white"}`} />
        </button>
    );
}

export default TranscriptionHandler;
