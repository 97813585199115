import userHooks from "./user.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useUpdateSettings = function () {
    const { user } = useAuth()

    async function updateSettings(params) {
        const { data, error, status } = await supabase.from("settings").update(params).eq("id", user.id).single()
        return data
    }

    return userHooks.useSettingsMutation(updateSettings)
}
