import { useCreatePresentation } from "./useCreatePresentation"
import { useDeletePresentation } from "./useDeletePresentation"
import { usePresentation } from "./usePresentation";
import { usePresentationMutation } from "./usePresentationMutation";
import { usePresentationQuery } from "./usePresentationsQuery";
import { useUpdatePresentation } from "./useUpdatePresentation";

const presentationHooks = {
    useCreatePresentation,
    useDeletePresentation,
    usePresentation,
    usePresentationMutation,
    usePresentationQuery,
    useUpdatePresentation
};

export default presentationHooks;