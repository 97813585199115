import { useMutation, useQueryClient } from "react-query"

export const useSlideMutation = function (mutateSlide) {
    const queryClient = useQueryClient()
    return useMutation(mutateSlide, {
        onSuccess: () => {
            queryClient.invalidateQueries(["slides"])
        },
    })
}
