import slideHooks from "./slide.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useCreateSlide = function () {
    const { user } = useAuth()

    async function createSlide({ presentationId, ranking }) {
        const { data, error, status } = await supabase.from("slides").insert({ presentation_id: presentationId, owner: user.id, ranking: ranking })
        return data
    }

    return slideHooks.useSlideMutation(createSlide)
}
