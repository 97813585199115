import { useState } from "react"
import Modal from "./Modal"
import Input from "../Input"
import FullWIdthSubmitButton from "../buttons/FullWIdthSubmitButton"
import presentationHooks from "../../hooks/Presentation/presentation.hooks"

function CreatePresentationModal({ isOpen, setIsOpen }) {
    const [title, setTitle] = useState("")
    const createPresentation = presentationHooks.useCreatePresentation()

    return (
        <Modal title="Create new Presentation" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form
                className="space-y-6 pt-6"
                onSubmit={function (e) {
                    e.preventDefault()
                    e.target.reset()
                    createPresentation.mutate(title)
                }}>
                <Input type="text" label="Presentation title" placeholder="Presentation title" setState={setTitle} required />
                <FullWIdthSubmitButton text="Create Presentation" onClick={() => setIsOpen(false)} />
            </form>
        </Modal>
    )
}

export default CreatePresentationModal
