import { useState, useRef, useEffect } from "react"
import RecordRTC, { StereoAudioRecorder } from "recordrtc"
import { MdPlayArrow, MdPause } from "react-icons/md"
import Toggle from "./Toggle"
import QRCodePopover from "./QRCodePopover"

function AudioStream({ presentationId, sio }) {
    const [playSwitch, setPlaySwitch] = useState(true)
    const [toggleState, setToggleState] = useState(true)
    const [remoteAudioStream, setRemoteAudioStream] = useState(false)
    const recorderRef = useRef(null)

    const startStream = async function () {
        if (sio.connected) {
            setPlaySwitch(false)
            const mediastream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

            recorderRef.current = new RecordRTC(mediastream, {
                type: "audio",
                mimeType: "audio/wav",
                desiredSampRate: 16000,
                timeSlice: 1000,
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                ondataavailable: function (blob) {
                    console.log("send something to the server")
                    sio.emit("audio_stream", blob)
                },
            })

            sio.emit("start_audio_stream")
            recorderRef.current.startRecording()
        } else {
            alert("No connection to server")
        }
    }

    const pauseStream = function () {
        setPlaySwitch(true)
        sio.emit("pause_audio_stream")
        recorderRef.current.stopRecording()
    }

    useEffect(() => {
        // Listen for RemoteAudioStreamRegistered and RemoteAudioStreamUnregistered event, and set the remoteAudioStream accordingly

        sio.on("RemoteAudioStreamRegistered", () => {
            setRemoteAudioStream(true)
            if (toggleState) {
                setToggleState(false)
                if (recorderRef.current) {
                    recorderRef.current.stopRecording()
                }
            }
        })

        sio.on("RemoteAudioStreamUnregistered", () => {
            setRemoteAudioStream(false)
        })

        return () => {
            sio.off("RemoteAudioStreamRegistered")
            sio.off("RemoteAudioStreamUnregistered")
        }
    }, [sio])

    return (
        <div className="flex items-center gap-2">
            <button
                // Disable the button if there is no connection to the server, or if the remote audio stream is registered, or if the toggle is off
                disabled={!sio.connected || remoteAudioStream || !toggleState}
                onClick={function () {
                    playSwitch ? startStream() : pauseStream()
                }}>
                {playSwitch ? (
                    <MdPlayArrow className={`h-7 w-7 ${toggleState ? "text-white" : "text-gray-400"}`} />
                ) : (
                    <MdPause className="h-7 w-7 text-white" />
                )}
            </button>

            <Toggle
                enabled={toggleState}
                setEnabled={setToggleState}
                screenReaderDescription="Switch between local microphone or remote microphone."
                // Disable if the playSwitch is false, or if the remoteAudioStream is true
                disabled={!playSwitch || remoteAudioStream}
            />

            <QRCodePopover disabled={toggleState} presentationId={presentationId} remoteAudioStream={remoteAudioStream} />
        </div>
    )
}

export default AudioStream
