import Spinner from "../components/Spinner"
import Input from "../components/Input"
import Header from "../components/Header"
import FullWidthSubmitButton from "../components/buttons/FullWIdthSubmitButton"
import { MdFileUpload } from "react-icons/md"
import { useState, useEffect } from "react"
import userHooks from "../hooks/User/user.hooks"
import uploadAvatar from "../services/uploadAvatar"
import updateAuth from "../hooks/Auth/updateAuth"
import { useAuth } from "../contexts/Auth"

function Settings() {
    const userQuery = userHooks.useUserQuery()
    const settingsQuery = userHooks.useSettingsQuery()
    const updateUser = userHooks.useUpdateUser()
    const updateSettings = userHooks.useUpdateSettings()
    const { user } = useAuth()
    const [profilephoto, setProfilephoto] = useState()
    const [previewPhoto, setPreviewPhoto] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [username, setUsername] = useState()
    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [s2t, setS2t] = useState()
    const [similarity, setSimilarity] = useState()
    const [classification, setClassification] = useState()
    const [recognition, setRecognition] = useState()
    const [tokens, setTokens] = useState()

    const clearProfileState = function () {
        setEmail()
        setPassword()
        setUsername()
        setFirstname()
        setLastname()
        setPreviewPhoto()
        setProfilephoto()
    }

    const clearSettingsState = function () {
        setS2t()
        setSimilarity()
        setClassification()
        setRecognition()
        setTokens()
    }

    useEffect(
        function () {
            if (profilephoto) {
                setPreviewPhoto(URL.createObjectURL(profilephoto))
            }
        },
        [profilephoto]
    )

    return (
        <div className="m-6">
            <Header text="Settings" iconName="MdSettings" />
            <div className="flex flex-col items-center">
                {settingsQuery.isSuccess && userQuery.isSuccess ? (
                    <>
                        <form
                            className="mt-3 w-1/2"
                            onSubmit={function (e) {
                                e.preventDefault()

                                updateAuth({ email: email, password: password })

                                updateUser.mutate({
                                    username: username,
                                    firstname: firstname,
                                    lastname: lastname,
                                    avatar_url: profilephoto ? uploadAvatar(profilephoto) : undefined,
                                })

                                e.target.reset()
                                clearProfileState()
                            }}>
                            <div className="mb-6 flex flex-col items-center">
                                <div className="relative">
                                    {previewPhoto ? (
                                        <img className="h-48 w-48 rounded-full object-cover" src={previewPhoto} alt="" />
                                    ) : (
                                        <>
                                            {userQuery.data.avatar_url ? (
                                                <img
                                                    className="h-48 w-48 rounded-full object-cover"
                                                    src={userQuery.data.avatar_url}
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="h-48 w-48 rounded-full bg-gray-300" />
                                            )}
                                        </>
                                    )}

                                    <div className="group absolute bottom-0 right-0 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-slate-50 hover:bg-primary-100">
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            onClick={function (e) {
                                                e.target.value = ""
                                            }}
                                            onChange={function (e) {
                                                setProfilephoto(e.target.files[0])
                                            }}
                                            className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                                        />
                                        <MdFileUpload className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-primary-500" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between gap-6">
                                <div className="grow">
                                    <span className="font-semibold">Firstname:</span>
                                    <Input defaultValue={userQuery.data.firstname} setState={setFirstname} />
                                </div>
                                <div className="grow">
                                    <span className="font-semibold">Lastname:</span>
                                    <Input defaultValue={userQuery.data.lastname} setState={setLastname} />
                                </div>
                            </div>

                            <span className="font-semibold">Email:</span>
                            <Input defaultValue={user.email} setState={setEmail} />

                            <span className="font-semibold">Username:</span>
                            <Input defaultValue={userQuery.data.username} setState={setUsername} />

                            <span className="font-semibold">Password:</span>
                            <Input setState={setPassword} />

                            <FullWidthSubmitButton text="Update Profile" />
                        </form>

                        <form
                            className="mt-10 w-1/2"
                            onSubmit={function (e) {
                                e.preventDefault()
                                updateSettings.mutate({
                                    s2t: s2t,
                                    similarity: similarity,
                                    classification: classification,
                                    recognition: recognition,
                                    token: tokens,
                                })
                                clearSettingsState()
                            }}>
                            <div className="mb-6 text-3xl font-semibold">Machine Learning Settings</div>

                            <div className="mb-6">
                                <span className="font-semibold">Speech to Text:</span>
                                <select
                                    id="s2t"
                                    name="country"
                                    onChange={function (e) {
                                        setS2t(e.target.value)
                                    }}
                                    defaultValue={settingsQuery.data.s2t}
                                    className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 ">
                                    <option>wav2vec</option>
                                    <option>azure</option>
                                    <option>hubert</option>
                                </select>
                            </div>

                            <div className="mb-6">
                                <span className="font-semibold">Similarity:</span>
                                <select
                                    id="similarity"
                                    onChange={function (e) {
                                        setSimilarity(e.target.value)
                                    }}
                                    defaultValue={settingsQuery.data.similarity}
                                    name="similarity"
                                    className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 ">
                                    <option>mpnet</option>
                                    <option>minilm</option>
                                    <option>xlm</option>
                                    <option>distilroberta</option>
                                    <option>ada</option>
                                    <option>babbage</option>
                                    <option>curie</option>
                                    <option>davinci</option>
                                </select>
                            </div>

                            <div className="mb-6">
                                <span className="font-semibold">Classification:</span>
                                <select
                                    id="classification"
                                    name="classification"
                                    onChange={function (e) {
                                        setClassification(e.target.value)
                                    }}
                                    defaultValue={settingsQuery.data.classification}
                                    className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 ">
                                    <option>bart</option>
                                </select>
                            </div>

                            <div className="mb-6">
                                <span className="font-semibold">Recognition:</span>
                                <select
                                    id="recognition"
                                    name="recognition"
                                    onChange={function (e) {
                                        setRecognition(e.target.value)
                                    }}
                                    defaultValue={settingsQuery.data.recognition}
                                    className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 ">
                                    <option>single</option>
                                    <option>nbest</option>
                                    <option>nbest_weighted</option>
                                </select>
                            </div>

                            <div className="mb-10">
                                <span className="font-semibold">Tokens:</span>
                                <select
                                    id="tokens"
                                    name="tokens"
                                    onChange={function (e) {
                                        setTokens(e.target.value)
                                    }}
                                    defaultValue={settingsQuery.data.token}
                                    className="mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 ">
                                    <option>paragraph</option>
                                    <option>sentence</option>
                                </select>
                            </div>

                            <FullWidthSubmitButton text="Update ML Settings" />
                        </form>

                        <div className="mt-12 max-w-6xl">
                            <button
                                onClick={() => {
                                    alert("That doesn't work at the moment, sorry.")
                                }}
                                className="w-full rounded-lg bg-primary-500 px-5 py-2.5 text-center text-sm font-bold text-white hover:bg-primary-600 focus:ring-4 focus:ring-primary-300">
                                Delete Account
                            </button>
                        </div>
                    </>
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    )
}

export default Settings
