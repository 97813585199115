import slideHooks from "../../hooks/Content/slide.hooks"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import Reveal from "reveal.js"
import { generateHTML } from "@tiptap/react"
import Spinner from "../Spinner"

import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Highlight from "@tiptap/extension-highlight"
import Bold from "@tiptap/extension-bold"
import Code from "@tiptap/extension-code"
import Italic from "@tiptap/extension-italic"
import Strike from "@tiptap/extension-strike"
import History from "@tiptap/extension-history"
import TextAlign from "@tiptap/extension-text-align"
import { Paragraph } from "../editor/extensions/Paragraph"
import { Heading } from "../editor/extensions/Heading"
import { OrderedList } from "../editor/extensions/OrderedList"
import { Image } from "../editor/extensions/Image"
import { BulletList } from "../editor/extensions/Bulletlist"
import { Blockquote } from "../editor/extensions/Blockquote"
import { VirginParagraph } from "../editor/extensions/VirginParagraph"
import { ListItem } from "../editor/extensions/ListItem"

import { useRef, useState } from "react"
import parse from "html-react-parser"

export default function SlidePresentation({ sio }) {
    const ref = useRef()
    const params = useParams()
    const { isSuccess, data } = slideHooks.useSlidesQuery(params.presentationId)
    const [deck, setDeck] = useState()

    useEffect(() => {
        if (isSuccess) {
            setDeck(
                new Reveal(ref.current, {
                    embedded: true,
                    controlsLayout: "edges",
                    controlsTutorial: false,
                    center: false,
                    width: 960,
                    height: 540,
                })
            )
        }
    }, [isSuccess])

    useEffect(() => {
        if (deck) {
            deck.initialize()
        }
    }, [deck])

    useEffect(() => {
        if (deck) {
            sio.on("slide", (data) => {
                console.log("received something:", data.slide)
                deck.slide(data.slide, 1)
            })
        }
        return () => {
            sio.off("slide")
        }
    }, [sio, deck])

    return (
        <div ref={ref} className="reveal absolute top-0 bottom-0 left-0 right-0">
            <div className="slides">
                {isSuccess && data ? (
                    data
                        .sort((a, b) => a.ranking - b.ranking)
                        .map((slide) => (
                            <section key={slide.ranking}>
                                {parse(
                                    generateHTML(JSON.parse(slide.content), [
                                        Document,
                                        Text,
                                        TextAlign.configure({
                                            types: ["heading", "paragraph"],
                                            defaultAlignment: "center",
                                        }),
                                        Paragraph,
                                        VirginParagraph,
                                        Heading,
                                        Highlight,
                                        Image,
                                        OrderedList,
                                        BulletList,
                                        ListItem,
                                        Blockquote,
                                        Bold,
                                        Code,
                                        Strike,
                                        Italic,
                                        History,
                                    ])
                                )}
                            </section>
                        ))
                ) : (
                    <div className="absolute top-0 bottom-0 left-0 right-0 rounded-lg bg-gray-300">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    )
}
