import { useQuery } from "react-query"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const usePresentationQuery = function (select) {
    const { user } = useAuth()

    async function fetchPresentations() {
        const { data, error, status } = await supabase.from("presentations").select(`*, slides(*)`).match({ owner: user.id })
        return data
    }

    return useQuery(["presentations"], fetchPresentations, { select })
}
