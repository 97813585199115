import { useParams } from "react-router-dom"
import { useEditor } from "@tiptap/react"

import Spinner from "../components/Spinner"
import SlideEditorMenuBar from "../components/editor/SlideEditorMenuBar"
import PresentationNotes from "../components/editor/PresentationNotes"
import SlideEditor from "../components/editor/SlideEditor"
import SlideManager from "../components/editor/SortableList/SlideManager"

import slideHooks from "../hooks/Content/slide.hooks"

import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Highlight from "@tiptap/extension-highlight"
import Bold from "@tiptap/extension-bold"
import Code from "@tiptap/extension-code"
import Italic from "@tiptap/extension-italic"
import Strike from "@tiptap/extension-strike"
import History from "@tiptap/extension-history"
import TextAlign from "@tiptap/extension-text-align"
import { Paragraph } from "../components/editor/extensions/Paragraph"
import { Heading } from "../components/editor/extensions/Heading"
import { OrderedList } from "../components/editor/extensions/OrderedList"
import { Image } from "../components/editor/extensions/Image"
import { BulletList } from "../components/editor/extensions/Bulletlist"
import { Blockquote } from "../components/editor/extensions/Blockquote"
import { ListItem } from "../components/editor/extensions/ListItem"
import { VirginParagraph } from "../components/editor/extensions/VirginParagraph"

import { SlideScaleProvider } from "../contexts/SlideScale"

function Content() {
    const params = useParams()
    const { isSuccess, data } = slideHooks.useSlidesQuery(params.presentationId)

    const editor = useEditor({
        extensions: [
            TextAlign.configure({
                types: ["heading", "paragraph"],
                defaultAlignment: "center",
            }),
            Document,
            Text,
            Paragraph,
            Heading,
            Highlight,
            Image,
            OrderedList,
            BulletList,
            ListItem,
            VirginParagraph,
            Blockquote,
            Bold,
            Code,
            Strike,
            Italic,
            History,
        ],
        editorProps: {
            attributes: {
                class: "pointer-events-none select-none h-0",
            },
        },
    })

    return (
        <div className="flex h-screen flex-col p-6">
            <SlideEditorMenuBar editor={editor} />

            <div className="relative flex-1">
                {isSuccess ? (
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex gap-3">
                        <SlideManager isSuccess={isSuccess} data={data} params={params} />

                        <div className="flex w-full flex-col gap-3">
                            <SlideScaleProvider>
                                <SlideEditor isSuccess={isSuccess} data={data} params={params} editor={editor} />
                            </SlideScaleProvider>

                            <PresentationNotes isSuccess={isSuccess} data={data} params={params} />
                        </div>
                    </div>
                ) : (
                    <div className="absolute top-0 bottom-0 left-0 right-0 rounded-lg bg-gray-300">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Content
