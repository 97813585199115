const config = {
    width: 960,
    height: 540,
    minScale: 0.2,
    maxScale: 2.0,
    margin: 0.04,
}

function getComputedSlideSize(presentationWidth, presentationHeight) {
    const size = {
        // Slide size
        width: config.width,
        height: config.height,

        // Presentation size
        presentationWidth: presentationWidth,
        presentationHeight: presentationHeight,
    }

    // Reduce available space by margin
    size.presentationWidth -= size.presentationWidth * config.margin
    size.presentationHeight -= size.presentationHeight * config.margin

    return size
}

export default function layout(slides, viewport, slide) {
    const size = getComputedSlideSize(viewport.offsetWidth, viewport.offsetHeight)

    slides.style.width = size.width + "px"
    slides.style.height = size.height + "px"

    // Determine scale of content to fit within available space
    var scale = Math.min(size.presentationWidth / size.width, size.presentationHeight / size.height)

    // Respect max/min scale settings
    scale = Math.max(scale, config.minScale)
    scale = Math.min(scale, config.maxScale)

    // Don't apply any scaling styles if scale is 1
    if (scale === 1) {
        slides.style.zoom = ""
        slides.style.left = ""
        slides.style.top = ""
        slides.style.bottom = ""
        slides.style.right = ""
        slides.style.transform = ""
    } else {
        slides.style.zoom = ""
        slides.style.left = "50%"
        slides.style.top = "50%"
        slides.style.bottom = "auto"
        slides.style.right = "auto"
        slides.style.transform = "translate(-50%, -50%) scale(" + scale + ")"
    }

    viewport.style.setProperty("--slide-scale", scale)

    return scale
}
