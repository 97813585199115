import loadable from "@loadable/component"

export function NavbarLink({ onClick, text, iconName }) {
    const Icon = loadable(() => import("react-icons/md/index.js"), {
        resolveComponent: (components) => components[iconName],
    })

    return (
        <div>
            <div
                onClick={function () {
                    onClick()
                }}
                className="group flex cursor-pointer items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-primary-50 hover:text-primary-700">
                <Icon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-primary-500" />
                <span className="ml-4">{text}</span>
            </div>
        </div>
    )
}
