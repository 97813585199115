import SidebarFooter from "./Footer.Sidebar"
import SidebarNavigation from "./Navigation.Sidebar"

function Sidebar() {
    return (
        <aside className="fixed h-full w-64" aria-label="Sidebar">
            <div className="flex min-h-full flex-col justify-between overflow-y-auto rounded-xl bg-slate-50 px-3 py-4">
                <SidebarNavigation />
                <SidebarFooter />
            </div>
        </aside>
    )
}

export default Sidebar
