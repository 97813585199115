import { useEffect } from "react"
import { useState } from "react"

function TranscriptionResults({ sio }) {
    const [resultText, setResultText] = useState()

    useEffect(() => {
        sio.on("recognition", (data) => {
            console.log("transcription result: ", data)
            setResultText(data)
        })
        return () => {
            sio.off("recognition")
        }
    }, [sio])

    return (
        <>
            {resultText && (
                <div className="pointer-events-none absolute bottom-3 z-50 w-full px-60 text-center text-lg text-white line-clamp-3">
                    <span className="inline rounded-sm bg-black/50 py-0.5 px-2">{resultText}</span>
                </div>
            )}
        </>
    )
}
export default TranscriptionResults
