import { useContext, useState, createContext } from "react"

const SlideScaleContext = createContext()

export function useScale() {
    return useContext(SlideScaleContext)
}

export function SlideScaleProvider({ children }) {
    const [slideScale, setSlideScale] = useState()

    const value = {
        setSlideScale: setSlideScale,
        slideScale: slideScale,
    }

    return <SlideScaleContext.Provider value={value}>{children}</SlideScaleContext.Provider>
}
