import { useState, useEffect } from "react"
import socketio from "socket.io-client"
import { supabase } from "../../services/supabaseClient"

export function useSocket(pid) {
    const session = supabase.auth.session()
    const [sio, setSio] = useState(null)
    const [connecting, setConnecting] = useState(false)

    useEffect(() => {
        if (!connecting) {
            setConnecting(true)

            console.log("creating socket")

            const socket = socketio(process.env.REACT_APP_SOCKETIO_LOCATION, {
                auth: session,
                query: { presentationId: pid },
            })

            socket.on("connect", () => {
                setSio(socket)
            })
        }

        return () => {
            sio?.disconnect()
        }
    }, [session, pid, sio])

    return sio
}
