import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const Paragraph = Node.create({
    name: "paragraph",

    priority: 1000,

    isolating: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    addAttributes() {
        return {
            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
                default: 100,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
                default: 100,
            },
        }
    },

    group: "block",

    content: "inline*",

    parseHTML() {
        return [{ tag: "p" }]
    },

    renderHTML({ HTMLAttributes, node }) {
        return [
            "p",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px; width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
            }),
            0,
        ]
    },

    addCommands() {
        return {
            setParagraph:
                () =>
                ({ commands }) => {
                    return commands.setNode(this.name)
                },
        }
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Alt-0": () => this.editor.commands.setParagraph(),
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => {
            return Draggable({ as: "p", props: props })
        })
    },
})
