import { mergeAttributes, Node } from "@tiptap/core"

export const VirginParagraph = Node.create({
    name: "virginParagraph",

    priority: 2000,

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    group: "block",

    content: "inline*",

    parseHTML() {
        return [{ tag: "p", context: "blockquote/|listItem/" }]
    },

    renderHTML({ HTMLAttributes }) {
        return ["p", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
})
