import { useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/Auth"

export const useLogin = function () {
    const navigate = useNavigate()
    const { signIn } = useAuth()

    return async function login(email, password) {
        const { error } = await signIn({
            email: email,
            password: password,
        })

        if (error) {
            alert("error signing in")
        } else {
            navigate("/")
        }
    }
}
