function BigCard({ children }) {
    return (
        <div className="flex h-screen flex-col items-center justify-center">
            <div className="w-full max-w-md">
                <div className="p-6">{children}</div>
            </div>
        </div>
    );
}

export default BigCard;
