import SlidePresentation from "../components/talk/SlidePresentation"
import IsTranscriptionActive from "../components/talk/IsTranscriptionActive"
import TranscriptionResults from "../components/talk/TranscriptionResult"
import TranscriptionHandler from "../components/talk/TranscriptionHandler"
import FullScreenHandler from "../components/talk/FullscreenHandler"
import AudioStream from "../components/talk/AudioStream"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSocket } from "../hooks/Socket/useSocket"

function Talk() {
    const params = useParams()
    const [transcriptionSwitch, setTranscriptionSwitch] = useState(false)
    const sio = useSocket(params.presentationId)

    useEffect(() => {
        if (sio) {
            sio.emit("init_presentation")
        }
    }, [sio])

    return (
        <div className="flex h-screen items-center justify-center bg-slate-200 p-6">
            <div className="group relative aspect-video h-full max-h-full max-w-full overflow-hidden rounded-xl bg-white drop-shadow-lg">
                {sio ? (
                    <>
                        <IsTranscriptionActive sio={sio} />
                        {transcriptionSwitch && <TranscriptionResults sio={sio} />}
                        <div className="absolute bottom-0 left-0 right-0 z-40 flex justify-between rounded-xl bg-gradient-to-t from-black/60 to-transparent p-3 opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100">
                            <AudioStream sio={sio} presentationId={params.presentationId} />

                            <div className="flex gap-6">
                                <TranscriptionHandler transcriptionSwitch={transcriptionSwitch} setTranscriptionSwitch={setTranscriptionSwitch} />
                                <FullScreenHandler />
                            </div>
                        </div>

                        <SlidePresentation sio={sio} />
                    </>
                ) : (
                    <div className="flex h-full items-center justify-center">Connecting to server...</div>
                )}
            </div>
        </div>
    )
}

export default Talk
