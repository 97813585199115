import { useCallback, useRef, useEffect } from "react"
import { debounce } from "lodash"
import noteHooks from "../../hooks/Note/note.hooks"

export default function PresentationNotes({ isSuccess, data, params }) {
    const updateNote = noteHooks.useUpdateNote()
    const debouncedNoteChangeHandler = useCallback(debounce(updateNoteFunction, 1500), [params])
    const textareaRef = useRef()

    function updateNoteFunction(e) {
        updateNote.mutate({ id: params.slideId, notes: e.target.value })
    }

    useEffect(() => {
        if (isSuccess) {
            textareaRef.current.value = data.find((obj) => {
                return obj.id === params.slideId
            }).notes
        }
    }, [isSuccess, params.slideId])

    return (
        <div className="flex-1 overflow-hidden rounded-lg bg-gray-100 p-3">
            <textarea
                onChange={(e) => {
                    debouncedNoteChangeHandler(e)
                }}
                ref={textareaRef}
                className="h-full w-full resize-none overflow-y-scroll bg-gray-100"
            />
        </div>
    )
}
