import Spinner from "../components/Spinner"
import Header from "../components/Header"
import ModalButton from "../components/buttons/ModalButton"
import IconButton from "../components/buttons/IconButton"
import { useNavigate } from "react-router-dom"
import presentationHooks from "../hooks/Presentation/presentation.hooks"
import parse from "html-react-parser"

import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Highlight from "@tiptap/extension-highlight"
import Bold from "@tiptap/extension-bold"
import Code from "@tiptap/extension-code"
import Italic from "@tiptap/extension-italic"
import Strike from "@tiptap/extension-strike"
import History from "@tiptap/extension-history"
import TextAlign from "@tiptap/extension-text-align"
import { Paragraph } from "../components/editor/extensions/Paragraph"
import { Heading } from "../components/editor/extensions/Heading"
import { OrderedList } from "../components/editor/extensions/OrderedList"
import { Image } from "../components/editor/extensions/Image"
import { BulletList } from "../components/editor/extensions/Bulletlist"
import { Blockquote } from "../components/editor/extensions/Blockquote"
import { VirginParagraph } from "../components/editor/extensions/VirginParagraph"
import { ListItem } from "../components/editor/extensions/ListItem"

import { generateHTML } from "@tiptap/react"
import Reveal from "reveal.js"
import { useEffect } from "react"

import "reveal.js/dist/reveal.css"
import "reveal.js/dist/theme/blood.css"

const Presentations = () => {
    const navigate = useNavigate()
    const deletePresentation = presentationHooks.useDeletePresentation()
    const { isSuccess, data } = presentationHooks.usePresentationQuery()

    useEffect(() => {
        if (isSuccess) {
            data.forEach((presentation) => {
                const element = document.querySelector(`[data-id="${presentation.id}"]`)

                const deck = new Reveal(element, {
                    embedded: true,
                    controlsLayout: "edges",
                    controlsTutorial: false,
                    center: false,
                    width: 960,
                    height: 540,
                })

                deck.initialize()
            })
        }
    }, [isSuccess])

    return (
        <div className="flex min-h-full flex-col p-6">
            <div className="flex justify-between">
                <Header text="Presentations" iconName="MdSlideshow" />
                <ModalButton text="Create Presentation" />
            </div>
            {isSuccess ? (
                Object.keys(data).length ? (
                    <div className="mt-6 grid grid-cols-5 gap-6">
                        {data.map((presentation) => (
                            <div key={presentation.id}>
                                <div className="max-w-sm rounded-lg border border-gray-200 bg-white shadow-md">
                                    <div className="relative aspect-video select-none overflow-hidden rounded-t-lg bg-gray-200">
                                        <div data-id={presentation.id} className="reveal absolute top-0 bottom-0 left-0 right-0">
                                            <div className="slides">
                                                {presentation.slides
                                                    .sort((a, b) => a.ranking - b.ranking)
                                                    .map((slide) => (
                                                        <section key={slide.ranking}>
                                                            {parse(
                                                                generateHTML(JSON.parse(slide.content), [
                                                                    Document,
                                                                    Text,
                                                                    TextAlign.configure({
                                                                        types: ["heading", "paragraph"],
                                                                        defaultAlignment: "center",
                                                                    }),
                                                                    Paragraph,
                                                                    VirginParagraph,
                                                                    Heading,
                                                                    Highlight,
                                                                    Image,
                                                                    OrderedList,
                                                                    BulletList,
                                                                    ListItem,
                                                                    Blockquote,
                                                                    Bold,
                                                                    Code,
                                                                    Strike,
                                                                    Italic,
                                                                    History,
                                                                ])
                                                            )}
                                                        </section>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                navigate(
                                                    `/presentations/${presentation.id}/${
                                                        presentation.slides.find((obj) => {
                                                            return obj.ranking === 0
                                                        }).id
                                                    }`
                                                )
                                            }>
                                            <h5 className="mb-7 truncate text-2xl font-bold tracking-tight text-gray-900">{presentation.title}</h5>
                                        </div>

                                        <div className="flex justify-between">
                                            <IconButton
                                                onClick={function () {
                                                    navigate(`/talk/${presentation.id}`)
                                                }}
                                                iconName="MdSlideshow"
                                            />
                                            <div className="flex gap-4">
                                                <IconButton
                                                    onClick={() =>
                                                        navigate(
                                                            `/presentations/${presentation.id}/${
                                                                presentation.slides.find((obj) => {
                                                                    return obj.ranking === 0
                                                                }).id
                                                            }`
                                                        )
                                                    }
                                                    iconName="MdMode"
                                                />
                                                <IconButton
                                                    className="bg-primary-300 text-white hover:bg-primary-400"
                                                    onClick={function () {
                                                        deletePresentation.mutate(presentation.id)
                                                    }}
                                                    iconName="MdDeleteOutline"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-auto items-center justify-center text-4xl font-bold text-gray-300">
                        <span>No presentations yet :(</span>
                    </div>
                )
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default Presentations
