import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

export const Image = Node.create({
    name: "image",

    addOptions() {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
        }
    },

    inline() {
        return this.options.inline
    },

    group() {
        return this.options.inline ? "inline" : "block"
    },

    addAttributes() {
        return {
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },

            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: this.options.allowBase64 ? "img[src]" : 'img[src]:not([src^="data:"])',
            },
        ]
    },

    renderHTML({ HTMLAttributes, node }) {
        return [
            "img",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px;  width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
            }),
        ]
    },

    addCommands() {
        return {
            setImage:
                (options) =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options,
                    })
                },
        }
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: (match) => {
                    const [, , alt, src, title] = match

                    return { src, alt, title }
                },
            }),
        ]
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => Draggable({ as: "img", props: props }))
    },
})
