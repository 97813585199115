import userHooks from "./user.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useUpdateUser = function () {
    const { user } = useAuth()

    async function updateUser(params) {
        const { data, error, status } = await supabase.from("profiles").update(params).eq("id", user.id).single()
        return data
    }

    return userHooks.useUserMutation(updateUser)
}
