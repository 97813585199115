import { useEffect, useState } from "react"
import { useSignUp } from "../hooks/Auth/useSignUp"
import BigCard from "../components/BigCard"
import Input from "../components/Input"
import FullWIdthSubmitButton from "../components/buttons/FullWIdthSubmitButton"
import { MdFileUpload } from "react-icons/md"

const Signup = function () {
    const [profilephoto, setProfilephoto] = useState()
    const [previewPhoto, setPreviewPhoto] = useState()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const signup = useSignUp()

    useEffect(
        function () {
            if (profilephoto) {
                setPreviewPhoto(URL.createObjectURL(profilephoto))
            }
        },
        [profilephoto]
    )

    return (
        <BigCard>
            <form
                onSubmit={function (e) {
                    e.preventDefault()

                    signup(email, password, username, firstname, lastname, profilephoto)
                }}>
                <div className="mb-6 flex flex-col items-center">
                    <div className="relative">
                        {previewPhoto ? (
                            <img className="h-48 w-48 rounded-full object-cover" src={previewPhoto} alt="" />
                        ) : (
                            <div className="h-48 w-48 rounded-full bg-gray-300" />
                        )}

                        <div className="group absolute bottom-0 right-0 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-slate-50 hover:bg-primary-50">
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onClick={function (e) {
                                    e.target.value = ""
                                }}
                                onChange={function (e) {
                                    setProfilephoto(e.target.files[0])
                                }}
                                className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                            />
                            <MdFileUpload className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-primary-500" />
                        </div>
                    </div>
                </div>

                <Input type="email" label="Your email" placeholder="name@example.com" setState={setEmail} required />
                <Input type="password" label="Your password" placeholder="Password" setState={setPassword} required />
                <Input type="text" label="Your username" placeholder="Username" setState={setUsername} required />

                <div className="grid xl:grid-cols-2 xl:gap-6">
                    <Input type="text" label="Your firstname" placeholder="Firstname" setState={setFirstname} required />
                    <Input type="text" label="Your lastname" placeholder="Lastname" setState={setLastname} required />
                </div>

                <FullWIdthSubmitButton text="Submit" />
            </form>
        </BigCard>
    )
}

export default Signup
