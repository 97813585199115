import { Popover, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { MdOutlineSmartphone } from "react-icons/md"
import QRCode from "react-qr-code"
import { Link } from "react-router-dom"

export default function QRCodePopover({ disabled, presentationId, remoteAudioStream }) {
    return (
        <div className=" w-full max-w-sm px-4">
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1">
                            <Popover.Panel className="absolute z-10 -translate-y-full transform pb-3">
                                <Link to={`/remote/${presentationId}`} className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                                    <QRCode value={`https://app.talk.iii.ooo/remote/${presentationId}`} />
                                </Link>
                            </Popover.Panel>
                        </Transition>

                        <Popover.Button disabled={disabled}>
                            <div className="relative h-7 w-7">
                                <MdOutlineSmartphone
                                    // If remoteAudioStream is true, set the color to text-green-400, if disabled, set the color to text-gray-400, otherwise set the color to text-white
                                    className={`absolute h-7 w-7 ${remoteAudioStream ? "text-green-400" : disabled ? "text-gray-400" : "text-white"}`}
                                />

                                <MdOutlineSmartphone
                                    className={`absolute h-7 w-7 animate-ping text-green-400 ${remoteAudioStream ? "opacity-100" : "opacity-0"}`}
                                />
                            </div>
                        </Popover.Button>
                    </>
                )}
            </Popover>
        </div>
    )
}
