import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"
import slideHooks from "./slide.hooks"

export const useUpdateSlide = function () {
    const { user } = useAuth()

    async function updateSlide({ id, content, ranking }) {
        const { data, error, status } = await supabase.from("slides").update({ content: content, ranking: ranking }).match({ id: id, owner: user.id })

        return data
    }

    return slideHooks.useSlideMutation(updateSlide)
}
