import { useCreateSlide } from "../../../hooks/Content/useCreateSlide"

export default function CreateNewSlide({ params, data }) {
    const createSlide = useCreateSlide()

    return (
        <button
            onClick={() => {
                createSlide.mutate({ presentationId: params.presentationId, ranking: data.length })
            }}
            className="inline-flex h-12 w-full items-center justify-center gap-2 rounded-lg bg-slate-200 text-slate-800 hover:bg-slate-300">
            <span className="material-symbols-rounded text-2xl ">add_circle</span>
            <span className="align-middle text-base font-normal text-gray-900">New Slide</span>
        </button>
    )
}
