import SlideSidebar from "./SlideSidebar"
import CreateNewSlide from "./CreateNewSlide"

export default function SlideManager({ data, isSuccess, params }) {
    return (
        <div className="flex h-full w-64 flex-none flex-col gap-2">
            <div className="relative flex flex-1 flex-col gap-2 overflow-y-auto overflow-x-hidden rounded-lg">
                <SlideSidebar data={data} isSuccess={isSuccess} />
            </div>
            <CreateNewSlide params={params} data={data} />
        </div>
    )
}
