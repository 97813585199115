import Sidebar from "../components/sidebar/Sidebar"
import { Outlet } from "react-router-dom"

const Layout = () => {
    return (
        <div className="h-screen">
            <Sidebar />
            <div className="absolute left-64 right-0 h-full">
                <Outlet />
            </div>
        </div>
    )
}

export default Layout
