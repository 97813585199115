import presentationHooks from "../Presentation/presentation.hooks"
import { supabase } from "../../services/supabaseClient"
import { useAuth } from "../../contexts/Auth"

export const useDeletePresentation = function () {
    const { user } = useAuth()

    async function deletePresentation(id) {
        return await supabase.from("presentations").delete().eq("id", id)
    }

    return presentationHooks.usePresentationMutation(deletePresentation)
}
