import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const inputRegex = /^\s*>\s$/

export const Blockquote = Node.create({
    name: "blockquote",

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    content: "virginParagraph",

    group: "block",

    defining: true,

    addAttributes() {
        return {
            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
                default: 100,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
                default: 100,
            },
        }
    },

    parseHTML() {
        return [{ tag: "blockquote" }]
    },

    renderHTML({ HTMLAttributes, node }) {
        return [
            "blockquote",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px;  width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
            }),
            0,
        ]
    },

    addCommands() {
        return {
            setBlockquote:
                () =>
                ({ commands }) => {
                    return commands.wrapIn(this.name)
                },
            toggleBlockquote:
                () =>
                ({ commands }) => {
                    return commands.toggleWrap(this.name)
                },
            unsetBlockquote:
                () =>
                ({ commands }) => {
                    return commands.lift(this.name)
                },
        }
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Shift-b": () => this.editor.commands.toggleBlockquote(),
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type,
            }),
        ]
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => Draggable({ as: "blockquote", props: props }))
    },
})
