import loadable from "@loadable/component";

function Header({ text, iconName }) {
    const Icon = loadable(() => import("react-icons/md/index.js"), {
        resolveComponent: (components) => components[iconName],
    });

    return (
        <div className="flex items-center gap-2">
            <Icon className="inline-flex h-10 w-10 text-gray-500 transition duration-75" />
            <h1 className="text-left text-2xl uppercase text-gray-500">{text}</h1>
        </div>
    );
}

export default Header;
