import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const inputRegex = /^\s*>\s$/

export const BulletList = Node.create({
    name: "bulletList",

    addOptions() {
        return {
            itemTypeName: "listItem",
            HTMLAttributes: {},
        }
    },

    group: "block list",

    addAttributes() {
        return {
            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
                default: 100,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
                default: 100,
            },
        }
    },

    content() {
        return `${this.options.itemTypeName}+`
    },

    parseHTML() {
        return [{ tag: "ul" }]
    },

    renderHTML({ HTMLAttributes, node }) {
        return [
            "ul",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px;  width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
            }),
            0,
        ]
    },

    addCommands() {
        return {
            toggleBulletList:
                () =>
                ({ commands }) => {
                    return commands.toggleList(this.name, this.options.itemTypeName)
                },
        }
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Shift-8": () => this.editor.commands.toggleBulletList(),
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type,
            }),
        ]
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => Draggable({ as: "ul", props: props }))
    },
})
