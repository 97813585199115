import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import Draggable from "../draggable/Draggable"

export const inputRegex = /^(\d+)\.\s$/

export const OrderedList = Node.create({
    name: "orderedList",

    addOptions() {
        return {
            itemTypeName: "listItem",
            HTMLAttributes: {},
        }
    },

    group: "block list",

    content() {
        return `${this.options.itemTypeName}+`
    },

    addAttributes() {
        return {
            start: {
                default: 1,
                parseHTML: (element) => {
                    return element.hasAttribute("start") ? parseInt(element.getAttribute("start") || "", 10) : 1
                },
            },

            top: {
                parseHTML: (element) => element.getAttribute("top"),
                rendered: false,
            },

            left: {
                parseHTML: (element) => element.getAttribute("left"),
                rendered: false,
            },

            width: {
                parseHTML: (element) => element.getAttribute("width"),
                rendered: false,
                default: 100,
            },

            height: {
                parseHTML: (element) => element.getAttribute("height"),
                rendered: false,
                default: 100,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: "ol",
            },
        ]
    },

    renderHTML({ HTMLAttributes, node }) {
        const { start, ...attributesWithoutStart } = HTMLAttributes

        return start === 1
            ? [
                  "ol",
                  mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart, {
                      style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px;  width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
                  }),
                  0,
              ]
            : [
                  "ol",
                  mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                      style: `position: absolute; top: ${node.attrs.top}px; left: ${node.attrs.left}px;  width: ${node.attrs.width}px; height: ${node.attrs.height}px;`,
                  }),
                  0,
              ]
    },

    addCommands() {
        return {
            toggleOrderedList:
                () =>
                ({ commands }) => {
                    return commands.toggleList(this.name, this.options.itemTypeName)
                },
        }
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Shift-7": () => this.editor.commands.toggleOrderedList(),
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: (match) => ({ start: +match[1] }),
                joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
            }),
        ]
    },

    addNodeView() {
        return ReactNodeViewRenderer((props) => Draggable({ as: "ol", props: props }))
    },
})
